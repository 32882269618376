import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class VaultPerkService {

  private urlVaultPerk = environment.urlVaultPerk;

  constructor(private http: HttpClient, private authService: AuthService) { }

  createPlan(info) {
    return this.http.post<any>(`${this.urlVaultPerk}/plan/create`, info, {
      headers: { 'x-access-token': this.authService.getToken() },
    });
  }

  getAllPlan() {
    return this.http.get<any>(`${this.urlVaultPerk}/plan/get`, {
      headers: { 'x-access-token': this.authService.getToken() },
    });
  }

  getPlanByName(name) {
    return this.http.get<any>(`${this.urlVaultPerk}/plan/getByName/${name}`, {
      headers: { 'x-access-token': this.authService.getToken() },
    });
  }

  updatePlan(id, info) {
    return this.http.put<any>(`${this.urlVaultPerk}/plan/update/${id}`, info, {
      headers: { 'x-access-token': this.authService.getToken() },
    });
  }

  deletePlan(id) {
    return this.http.delete<any>(`${this.urlVaultPerk}/plan/delete/${id}`, {
      headers: { 'x-access-token': this.authService.getToken() },
    });
  }

  createSeguro(info) {
    return this.http.post<any>(`${this.urlVaultPerk}/seguro/create`, info, {
      headers: { 'x-access-token': this.authService.getToken() },
    });
  }

  getSeguroById(id) {
    return this.http.get<any>(`${this.urlVaultPerk}/seguro/get/${id}`, {
      headers: { 'x-access-token': this.authService.getToken() },
    });
  }

  getSeguroByUser(id) {
    return this.http.get<any>(`${this.urlVaultPerk}/seguro/getByUsuario/${id}`, {
      headers: { 'x-access-token': this.authService.getToken() },
    });
  }

  updateSeguro(id, info) {
    return this.http.put<any>(`${this.urlVaultPerk}/seguro/update/${id}`, info, {
      headers: { 'x-access-token': this.authService.getToken() },
    });
  }

  deleteSeguro(id) {
    return this.http.delete<any>(`${this.urlVaultPerk}/seguro/delete/${id}`, {
      headers: { 'x-access-token': this.authService.getToken() },
    });
  }

  activateSeguro(id) {
    return this.http.put<any>(`${this.urlVaultPerk}/seguro/activate/${id}`, {}, {
      headers: { 'x-access-token': this.authService.getToken() },
    });
  }

  deactivateSeguro(id) {
    return this.http.put<any>(`${this.urlVaultPerk}/seguro/deactivate/${id}`, {}, {
      headers: { 'x-access-token': this.authService.getToken() },
    });
  }

  createGrupo(info) {
    return this.http.post<any>(`${this.urlVaultPerk}/grupo/create`, info, {
      headers: { 'x-access-token': this.authService.getToken() },
    });
  }

  updateGrupo(id, info) {
    return this.http.put<any>(`${this.urlVaultPerk}/grupo/update/${id}`, info, {
      headers: { 'x-access-token': this.authService.getToken() },
    });
  }

  deleteGrupo(id) {
    return this.http.delete<any>(`${this.urlVaultPerk}/grupo/delete/${id}`, {
      headers: { 'x-access-token': this.authService.getToken() },
    });
  }

  getAllGrupo() {
    return this.http.get<any>(`${this.urlVaultPerk}/grupo/get`, {
      headers: { 'x-access-token': this.authService.getToken() },
    });
  }

  getGrupoByName(name) {
    return this.http.get<any>(`${this.urlVaultPerk}/grupo/getByName/${name}`);
  }

  getPol(name) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get<any>(`${this.urlVaultPerk}/grupo/getPolByName/${name}`,
      { headers: headers, responseType: 'blob' as 'json' });
  }

}
